import { IEntityRoutes } from "../interfaces/login";
import config from "../config/config";
import { ISentryLevel } from "../interfaces/utils";
import { IInitialConfigRules } from "../interfaces/configuration";
import { IDynamicObjectReturnString } from "../interfaces/common";
import { IOrderStatus } from "../interfaces/order";

const { AUTH_API_BASE_URL, S3_BASE_URL } = config;
export const LIMIT = 15;
export const VERSIONS_PATH = "./gitHookHandlers/versions.json";
export const PACKAGE_JSON_PATH = "./package.json";
export const LOCALE = "es-AR";
export const MARKETPLACE_PATH = "/tienda";
export const ECOMMERCE_PATH = "/cliente";
export const MERCHANT_PATH = "/merchant";
export const INTERNAL_PATH = "/internal";
export const MERCHANT_ERROR_INTEGRATION_PATH = "/merchant/error-integracion";
export const PARENT_PATH = "../";
export const LANDING_PATH = "/";
export const AUTHENTICATION_PATH = "/autenticacion";
export const CONFIGURATION_PATH = "/configuracion";
export const ACCOUNT_PATH = "/cuenta";
export const NOT_FOUND_PATH = "/no-encontrado";
export const STORES_MANAGEMENT_PATH = "/merchant/gestion-marketplaces";
export const PATHS_TO_HIDE_CONTENT = [
  "gestion-marketplaces",
  "cuenta",
  "facturacion",
  "notificaciones",
  "mis-datos",
];
export const FREE_PLAN_SIGNUP_PATH = "/autenticacion/registro-cliente?plan=";
export const NOTIFICATIONS_PATH = "/notificaciones";
export const AUDITS_PATH = "/auditoria";
export const CONTACTO_URL = "/contacto";
export const SEGUIMIENTO_DE_ENVIOS_URL = "/seguimiento-de-envios";
export const PARTNERS_URL = "/partners";
export const PARTNERS_PLAN_NAME = "Partner";
export const FREE_PLAN_NAME = "Free";
export const MEDIUM_BREAKPOINT = 768;

export const API_DOCS_URL = `${AUTH_API_BASE_URL}/docs`;
export const API_KEY_STAGING_MOCK = "OHASF8845asd4";

//statuses
export const OK = 200;
export const CREATED = 201;
export const NOT_MODIFIED = 304;
export const PERMANENT_REDIRECT = 308;
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const TOKEN_EXPIRED = 498;
export const INTERNAL_ERROR = 500;
export const ERR_NETWORK_CODE = "ERR_NETWORK";
export const NON_EXISTENT_INTEGRATION = "non-existent-integration";
export const VERIFY_ACCOUNT =
  "Verifica tu correo electrónico para validar la cuenta";

export const POST = "post";
export const GET = "get";
export const PUT = "put";
export const PATCH = "patch";
export const DELETE = "delete";

export const PRIVATE = "private";
export const PUBLIC = "public";

export const CREATE_SPANISH = "Creación";
export const CANCEL_SPANISH = "Cancelación";
export const RETURN_SPANISH = "Devolución";

export const LOGS_ACTION_SPANISH: { [key: string]: string } = {
  create: CREATE_SPANISH,
  cancel: CANCEL_SPANISH,
  return: RETURN_SPANISH,
};

export const DRAWER_WIDTH = 220;

export const ROWS_PER_PAGE = 10;

export const TIME_DELAY = 2000;

export const MARKETPLACE = "marketplace";
export const ECOMMERCE = "ecommerce";
export const MERCHANT = "merchant";
export const INTERNAL = "internal";
export const MARKETPLACE_MERCHANT = "marketplace_merchant";
export const CLIENT_TYPES = [MARKETPLACE, ECOMMERCE];

export const MAX_NOTIFICATIONS = 10;

export const ENTITY_ROUTES: IEntityRoutes = {
  marketplace: MARKETPLACE_PATH,
  ecommerce: ECOMMERCE_PATH,
  merchant: MERCHANT_PATH,
  internal: INTERNAL_PATH,
};

export const ENTITY_ROUTES_SP: IEntityRoutes = {
  marketplace: "tienda",
  merchant: "merchant",
  ecommerce: "ecommerce",
};

export const ACCOUNT_PERMISSION = "account";
export const BILLING_PERMISSION = "billing";
export const CONFIGURATION_PERMISSION = "configuration";
export const CUSTOMIZATION_PERMISSION = "customization";
export const EXPORT_PERMISSION = "export";
export const MANAGMENT_PERMISSION = "managment";
export const ORDERS_PERMISSION = "orders";
export const USERS_PERMISSION = "users";
export const SYS_CARRIERS_PERMISSION = "sys-carriers";
export const SYS_CLIENTS_PERMISSION = "sys-clients";
export const SYS_METRICS_PERMISSION = "sys-metrics";
export const SYS_LOGS_PERMISSION = "sys-logs";

export const LARGE = "lg";
export const MEDIUM = "md";
export const SMALL = "sm";

export const ERROR = "error";
export const WARNING = "warning";
export const INFO = "info";
export const SUCCESS = "success";

export const PAUSED = "paused";

export const OCA = "OCA";
export const ANDREANI = "ANDREANI";
export const OCASA = "OCASA";
export const RAPIBOY = "RAPIBOY";
export const CHAZKI = "CHAZKI";
export const HOP = "HOP";

export const INTEGRATIONS_STATUSES: any = {
  accepted: "Aceptada",
  pending: "Pendiente",
  "accepted without registration": "Aceptada sin registro",
};

export const IVA_SITUATIONS = [
  "IVA Responsable Inscripto",
  "Consumidor Final",
  "Responsable Monotributo",
  "Sujeto No Categorizado",
  "Proveedor del Exterior",
  "Cliente del Exterior",
  "IVA Liberado - Ley N° 19.640",
  "Monotributista Social",
  "IVA No Alcanzado",
  "Monotributista Trabajador Independiente Promovido",
];

export const COME_BACK_LATER_MESSAGE =
  "Ha ocurrido un error, vuelve a intentarlo más tarde.";

export const CONFIG_MESSAGES = [
  "Para adherirte a un operador logístico con un contrato propio deberás seleccionar contrato propio, cargarlo y confirmar. Para adherirte con nuestro contrato deberás seleccionar contrato coatí y confirmar.",
  "Si deseas trabajar con otro operador logístico comunicate con nosotros a contacto@coatiapp.com",
];
export const REQUIRED_SUBSTRING = "requerid";
export const ERRORS_MESSAGES = {
  passwordInvalid:
    "Contraseña inválida. Mínimo 8 caracteres, al menos una letra mayúscula y una minúscula, al menos un número y un carácter especial.",
  passwordRequired: "Contraseña requerida.",
  passwordConfirmation: "Las contraseñas deben ser iguales.",
  emailInvalid: "Correo electrónico inválido.",
  emailRequired: "Correo electrónico requerido.",
  entityRequired: "Tipo de negocio requerido.",
  nameRequired: "Alias requerido.",
  ivaSituationRequired: "Condición ante el IVA requerida.",
  ivaRequired: "IVA requerido.",
  businessNameRequired: "Razón Social requerida.",
  cuitRequired: "CUIT requerido.",
  cuitInvalid:
    "El número de CUIT es incorrecto. Ingresar número de CUIT sin guiones.",
  commercialAddressRequired: "Domicilio requerido.",
  orderSearchQueryRequired: "Número de envío requerido.",
  orderSearchQueryInvalid: "Número de envío inválido.",
  idClientRequired: "Id de cliente requerido.",
  userRequired: "Usuario requerido.",
  doorToDoorRequired: "Código de contrato puerta a puerta requerido.",
  reverseRequired: "Código de contrato de logística inversa requerido.",
  endDateRequired: "Fecha de expiración del contrato requerida.",
  doorToDoorEndDateRequired:
    "La fecha del contrato puerta a puerta es requerida.",
  reverseLogisticsEndDateRequired:
    "La fecha del contrato de logística inversa es requerida.",
  accountNumberRequired: "Número de cuenta requerido.",
  monthlyShippingsRequired:
    "La cantidad envíos mensuales es información requerida.",
  endDateNotInThePast:
    "La fecha de expiración del contrato debe ser posterior a la actual.",
  doorToDoorEndDateNotInThePast:
    "La fecha de expiración del contrato puerta a puerta debe ser posterior a la actual.",
  reverseLogisticsEndDateNotInThePast:
    "La fecha de expiración del contrato de logística inversa debe ser posterior a la actual.",
  contactNameRequired: "El nombre es requerido.",
  messageRequired: "El mensaje es requerido.",
  fromDateRequired: "Fecha desde requerida.",
  toDateRequired: "Fecha hasta requerida.",
  tokenRequired: "El token es requerido.",
  carrierRequired: "El operador logístico es requerido.",
  reverseOnlyNumbers:
    "El código de contrato de logística inversa solo debe contener números.",
  doorToDoorOnlyNumbers:
    "El código de contrato puerta a puerta solo debe contener números.",
  commercialAddressHasToContainNumbers:
    "La altura del domicilio comercial es requerida.",
  securityCodeRequired: "El código de seguridad es requerido.",
  widthRequired: "El ancho es requerido.",
  heightRequired: "El alto es requerido.",
  depthRequired: "La profundidad es requerida.",
  weightRequired: "El peso es requerido.",
  widthGreatherThanZero: "El ancho debe ser mayor a 0.",
  heightGreatherThanZero: "La altura debe ser mayor a 0.",
  depthGreatherThanZero: "La profundidad debe ser mayor a 0.",
  weightGreatherThanZero: "El peso debe ser mayor a 0.",
  onlyNumbers: "Este campo solo debe contener números.",
  onlyPositiveNumbers: "Este campo solo debe contener números positivos.",
  clientCodeRequired: "Código de cliente requerido.",
  oneDoorToDoorRequired:
    "Es necesario contar con al menos un contrato puerta a puerta.",
  monthlyShippings: "Los envíos mensuales deben ser un número mayor a 0.",
  invalidMatrixFormat: "La matriz debe ser un archivo de formato xlsx",
  roleRequired: "El rol es requerido",
  hopIdRequired: "El ID de hop es requerido.",
  hopSecretRequired: "El codigo secreto de hop es requerido.",
  sellerCodeRequired: "El código de vendedor es requerido.",
};

export const CUIT_PLACEHOLDER = "Ingresar número de CUIT sin guiones.";

export const VALID_INITIAL_CUIT_DIGITS = [
  "20",
  "23",
  "24",
  "25",
  "26",
  "27",
  "30",
  "33",
  "34",
];
export const SIGNUP = "signup";
export const SIGN_UP_SUCCESS_MESSAGE = "Tu usuario ha sido creado con éxito.";

export const OL_VALID_PATHS = [
  "operadores-logisticos",
  "operadores-logisticos/carga-manual",
  "operadores-logisticos/coatizador",
  "operadores-logisticos/credenciales-propias",
  "cuenta",
  "suscripcion-y-facturacion",
  "ayuda", // TODO para cuando exista ayuda
];

export const SPANISH_SERVICES: any = {
  DOOR_TO_DOOR: "Envío",
  REVERSE_LOGISTICS: "Devolución",
};

export const SPANISH_MODALITIES: any = {
  NextDay: "Next Day",
  SameDay: "Same Day",
  DOOR_TO_DOOR_PRIORITY: "Prioritario",
  DOOR_TO_DOOR_STANDARD: "Estándar",
};

// Possible order status
// TODO agregar estados Pendiente de aprobacion por OL, aprobado, rechazado (Para ANDREANI)
export const PENDING = "pending";
export const IN_PROCESS = "in process";
export const IN_TRANSIT = "in transit";
export const RESCHEDULED = "rescheduled";
export const SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE =
  "shipment temporary in branch office";
export const DELIVERED = "delivered";
export const CANCELED = "canceled";
export const RETURNED_TO_SENDER = "returned to sender";
export const PENDING_RETURN = "pending to return";
export const SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE_TO_WITHDRAW =
  "shipment temporary in branch office to withdraw";
export const RETURN_IN_PROCESS = "return in process";

// DOOR TO PICKUP POINT
export const IN_PICKUP_POINT = "in pickup point";

// INVERSE_LOGISTICS
export const PENDING_WITHDRAWAL = "pending withdrawal";
export const NULLIFIED = "nullified";
export const IN_TRANSIT_WITHDRAWAL = "in transit to withdrawal";
export const IN_PROCESS_TO_RETURN = "in process to return";
export const RETURNED = "returned";

// SPANISH order status
export const SP_PENDING = "Pendiente";
export const SP_IN_PROCESS = "En proceso";
export const SP_IN_TRANSIT = "En camino";
export const SP_RESCHEDULED = "Reprogramado";
export const SP_SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE =
  "Entregado temporalmente en sucursal";
export const SP_DELIVERED = "Entregado";
export const SP_CANCELED = "Cancelado";
export const SP_RETURNED_TO_SENDER = "Devuelto al origen";
export const SP_PENDING_RETURN = "Pendiente de devolución";
export const SP_SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE_TO_WITHDRAW =
  "Espera de retiro en sucursal";

export const SP_IN_PICKUP_POINT = "Espera en punto de retiro";

export const SP_PENDING_WITHDRAWAL = "Pendiente de retiro";
export const SP_NULLIFIED = "Anulada";
export const SP_IN_TRANSIT_WITHDRAWAL = "En camino a retirar";
export const SP_IN_PROCESS_TO_RETURN = "En proceso de devolución";
export const SP_RETURNED = "Devuelto";
export const SP_RETURN_IN_PROCESS = "Devolución en curso";

export const SHIPMENT = "Envío";
export const RETURN = "Devolución";
export const PICKUP = "Envío a sucursal";

export const CUSTOMIZABLE_TEMPLATES = [
  PENDING,
  CANCELED,
  IN_TRANSIT,
  RESCHEDULED,
  DELIVERED,
  // IN_PROCESS,
  // SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE,
  SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE_TO_WITHDRAW,
  // RETURNED_TO_SENDER,
  // IN_TRANSIT_WITHDRAWAL,
  // IN_PROCESS_TO_RETURN,
  PENDING_WITHDRAWAL,
  NULLIFIED,
  // IN_PICKUP_POINT,
];

export const ORDER_STATUS: IOrderStatus[] = [
  {
    label: SP_PENDING,
    id: PENDING,
    description: "El vendedor está preparando tu pedido.",
    type: SHIPMENT,
  },
  {
    label: SP_IN_PROCESS,
    id: IN_PROCESS,
    description: "La logística tiene tu producto.",
    type: SHIPMENT,
  },
  {
    label: SP_IN_TRANSIT,
    id: IN_TRANSIT,
    description:
      "Te visitaremos hoy por tu domicilio para entregarte tu producto.",
    type: SHIPMENT,
  },
  {
    label: SP_RESCHEDULED,
    id: RESCHEDULED,
    description:
      "No te encontramos en tu domicilio. En los próximos días volveremos a pasar.",
    type: SHIPMENT,
  },
  {
    label: SP_SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE,
    id: SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE,
    description: "Tu pedido llegó a la sucursal a cargo de la entrega.",
    type: SHIPMENT,
  },
  {
    label: SP_DELIVERED,
    id: DELIVERED,
    description: "Tu pedido ya fue entregado con éxito.",
    type: SHIPMENT,
  },
  {
    label: SP_CANCELED,
    id: CANCELED,
    description: "El pedido ha sido cancelado.",
    type: SHIPMENT,
  },
  {
    label: SP_RETURN_IN_PROCESS,
    id: RETURN_IN_PROCESS,
    description: "Tu producto no pudo ser entregado.",
    type: SHIPMENT,
  },
  {
    label: SP_RETURNED_TO_SENDER,
    id: RETURNED_TO_SENDER,
    description: "El pedido ha regresado a su lugar de origen.",
    type: SHIPMENT,
  },
  {
    label: SP_SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE_TO_WITHDRAW,
    id: SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE_TO_WITHDRAW,
    description:
      "No te encontramos en tu domicilio. Tu pedido está disponible para retiro en sucursal. Para más información, por favor revisá tu correo electrónico.",
    type: SHIPMENT,
  },
  {
    label: SP_PENDING_RETURN,
    id: PENDING_RETURN,
    description: "El pedido se encuentra en proceso de devolución.",
    type: SHIPMENT,
  },
  {
    label: SP_PENDING_WITHDRAWAL,
    id: PENDING_WITHDRAWAL,
    description:
      "Hemos registrado exitosamente tu pedido de devolución de compra. En los próximos días estaremos visitando en tu domicilio para retirar el producto.",
    type: RETURN,
  },
  {
    label: SP_NULLIFIED,
    id: NULLIFIED,
    description:
      "Pasamos por el domicilio de destino en reiteradas oportunidades y no te encontramos.",
    type: RETURN,
  },
  {
    label: SP_IN_TRANSIT_WITHDRAWAL,
    id: IN_TRANSIT_WITHDRAWAL,
    description:
      "Hoy te estaremos visitando en tu domicilio para retirar el producto en devolución.",
    type: RETURN,
  },
  {
    label: SP_IN_PROCESS_TO_RETURN,
    id: IN_PROCESS_TO_RETURN,
    description: "El pedido ha salido del centro de distribución.",
    type: RETURN,
  },
  {
    label: SP_RETURNED,
    id: RETURNED,
    description: "El vendedor ha recibido el pedido.",
    type: RETURN,
  },
  {
    label: SP_IN_PICKUP_POINT,
    id: IN_PICKUP_POINT,
    description:
      "El pedido se encuentra en la sucursal esperando ser retirada.",
    type: PICKUP,
  },
];

export const FINAL_STATUS_LIST = [
  RETURNED_TO_SENDER,
  DELIVERED,
  NULLIFIED,
  RETURNED,
  CANCELED,
];

export const FINAL_DOOR_TO_DOOR_STATUS: IOrderStatus[] = [
  {
    label: SP_RETURNED_TO_SENDER,
    id: RETURNED_TO_SENDER,
    description: "El pedido ha regresado a su lugar de origen.",
    type: SHIPMENT,
  },
  {
    label: SP_DELIVERED,
    id: DELIVERED,
    description: "El pedido ha sido entregado.",
    type: SHIPMENT,
  },
];
export const FINAL_REVERSE_LOGISTICS_STATUS: IOrderStatus[] = [
  {
    label: SP_NULLIFIED,
    id: NULLIFIED,
    description:
      "Pasamos por el domicilio de destino en reiteradas oportunidades y no te encontramos.",
    type: RETURN,
  },
  {
    label: SP_RETURNED,
    id: RETURNED,
    description: "El vendedor ha recibido el pedido.",
    type: RETURN,
  },
];

export const STATUS_TRANSLATOR: any = {
  [PENDING]: SP_PENDING,
  [IN_PROCESS]: SP_IN_PROCESS,
  [IN_TRANSIT]: SP_IN_TRANSIT,
  [RESCHEDULED]: SP_RESCHEDULED,
  [SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE]: SP_SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE,
  [DELIVERED]: SP_DELIVERED,
  [CANCELED]: SP_CANCELED,
  [RETURNED_TO_SENDER]: SP_RETURNED_TO_SENDER,
  [SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE_TO_WITHDRAW]:
    SP_SHIPMENT_TEMPORARY_IN_BRANCH_OFFICE_TO_WITHDRAW,
  [IN_PICKUP_POINT]: SP_IN_PICKUP_POINT,
  [PENDING_RETURN]: SP_PENDING_RETURN,
  [PENDING_WITHDRAWAL]: SP_PENDING_WITHDRAWAL,
  [NULLIFIED]: SP_NULLIFIED,
  [IN_TRANSIT_WITHDRAWAL]: SP_IN_TRANSIT_WITHDRAWAL,
  [IN_PROCESS_TO_RETURN]: SP_IN_PROCESS_TO_RETURN,
  [RETURNED]: SP_RETURNED,
};

export const STATUS_TO_SHOW_CANCEL_AND_LABEL_DOWNLOAD = [
  PENDING,
  PENDING_WITHDRAWAL,
];
export const STATUS_TO_SHOW_REFUND_ICON = [DELIVERED];

export const CONTACT_ALERT_MESSAGE =
  "Si deseas trabajar con otro operador logístico comunícate con nosotros a contacto@coatiapp.com";

export const OWN_CREDENTIALS = "own-credentials";
export const COATI_CREDENTIALS = "coati-credentials";

export const SELECT_CONTRACT = "select-contract";
export const VALID_TRACKING_NUMBER_LENGTHS = [16, 19];

export const LANDING_NAVIGATION_ANCHORS = [
  {
    label: "Beneficios",
    href: LANDING_PATH,
  },
  {
    label: "Partners",
    href: PARTNERS_URL,
  },
  {
    label: "Contacto",
    href: CONTACTO_URL,
  },
  {
    label: "Seguí tu envío",
    href: SEGUIMIENTO_DE_ENVIOS_URL,
  },
  {
    label: "API Docs",
    href: API_DOCS_URL,
  },
];

export const BENEFITS = [
  "Realizá envíos a todo el país.",
  "Cargá tus propios contratos.",
  "Accedé al mejor operador logístico para cada envío.",
  "Despachá tus productos sin restricción de peso ni volumen.",
];

export const SELECT_RESPONSIBLE = "Seleccionar responsable";

// Stock options
export const SELECT_MODE = "Seleccionar modalidad";
export const AUTOMATIC_STATE_UPDATE = "Notificación automática de estados";
export const MANUAL_STATE_UPDATE = "Notificación manual de estados";

export const CONFIGURATION_RULES = [
  {
    id: MARKETPLACE_MERCHANT,
    text: "Marketplace y Merchants",
  },
  {
    id: MARKETPLACE,
    text: "Sólo Marketplace",
  },
];

const ENTITY_RULES = [
  {
    id: MARKETPLACE_MERCHANT,
    text: "Marketplace y Merchants",
  },
  {
    id: MERCHANT,
    text: "Sólo Merchant",
  },
  {
    id: MARKETPLACE,
    text: "Sólo Marketplace",
  },
];

export const RULES_OPTIONS: IInitialConfigRules[] = [
  {
    name: "merchantCanDisableCarriers",
    title: "¿Quién puede desactivar los operadores logísticos?",
    options: CONFIGURATION_RULES,
  },
  {
    name: "entitiesAbleToReturn",
    title: "¿Quién puede realizar devoluciones?",
    options: ENTITY_RULES,
  },
  {
    name: "entitiesAbleToCancel",
    title: "¿Quién puede realizar cancelaciones?",
    options: ENTITY_RULES,
  },
  // {
  //   name: "entitiesAbleToChangeStatus",
  //   title: "¿Quién puede realizar el cambio de estado final a una orden?",
  //   options: ENTITY_RULES,
  // },
];

export const BENEFIT_ICONS = [
  {
    title: "Múltiples transportes",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, odit.",
    icon: "local_shipping",
  },
  {
    title: "Cobertura nacional",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, odit.",
    icon: "pin_drop",
  },
  {
    title: "Todos tus productos",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, odit.",
    icon: "trolley",
  },
  {
    title: "Seguimiento online",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, odit.",
    icon: "barcode_scanner",
  },
  {
    title: "Logística inversa",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, odit.",
    icon: "sync_alt",
  },
  {
    title: "Atención y soporte",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, odit.",
    icon: "support_agent",
  },
  {
    title: "Facturación consolidada",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, odit.",
    icon: "receipt_long",
  },
  {
    title: "Red Coatí",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, odit.",
    icon: "travel_explore",
  },
  {
    title: "Tus contratos",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestias, odit.",
    icon: "order_approve",
  },
];

export const TRACKING_TIPS = [
  "El envío lo puede recibir cualquier persona mayor de 18 años.",
  "No aceptes un envío con el empaque visiblemente dañado.",
  "No es posible modificar la dirección de entrega. Esta es una medida de prevención de fraude.",
];

export const ERROR_FROM_GREATER_THAN_TO =
  "Fecha desde no puede ser superior a fecha hasta.";
export const ERROR_TO_GREATER_THAN_FROM =
  "Fecha hasta no puede ser inferior a fecha desde.";

export const SECTION_ONE =
  "Conectamos marketplaces con múltiples operadores logísticos";
export const SECTION_TWO =
  "Inicia la prueba gratuita con una licencia de 3 meses";
export const SECTION_THREE = "Integra, automatiza y gestiona tus envíos";

export const CONTACT_TITLE_ONE = "¿Querés conocer más sobre nuestro producto?";
export const CONTACT_TITLE_TWO =
  "¿Querés sumarte a nuestra red de operadores logísticos?";

export const PARTNERS_TITLE = "Partners";

export const PASSWORD_HELPER =
  "Usar 8 o más caracteres con una combinación de letras, números y símbolos.";

export const CANCEL_INVITATION_MESSAGE = "Invitación cancelada con éxito.";

export const CONFIG_SUCCESS_MESSAGE =
  "La configuración fue actualizada con éxito.";
export const CONTACT_SUCCESS_MESSAGE =
  "¡Muchas gracias por contactar con nosotros! Te responderemos a la brevedad.";

export const CONTACT_MAX_CHARACTERS = 255;

export const ORDER_NOT_DELIVERED_MESSAGE =
  "Esta orden no es apta para devolución.";
export const ORDER_NOT_OWN_MESSAGE = "Esta orden no pertenece a esta entidad.";
export const ORDER_REFUND_SUCCESS_MESSAGE =
  "La orden de devolución ha sido creada con éxito.";
export const ORDER_REFUND_ERROR_MESSAGE =
  "Ha ocurrido un error al devolver la orden.";

// Storage
export const TOKEN = "token";
export const CURRENT_MARKETPLACE = "currentMarketplace";
export const MARKETPLACE_NAME = "marketplaceName";
export const IS_LOGOUT = "isLogout";
export const INITIAL_CONFIGURATION_COMPLETE = "initialConfigurationComplete";
export const VALID_STORAGE_KEYS = [
  TOKEN,
  CURRENT_MARKETPLACE,
  MARKETPLACE_NAME,
  IS_LOGOUT,
  INITIAL_CONFIGURATION_COMPLETE,
];
export const NO_LABELS_MESSAGE =
  "No se encontraron órdenes que cumplan las reglas indicadas.";

export const CARRIER_MAIN_PAGE: IDynamicObjectReturnString = {
  [OCA]: "https://www.oca.com.ar/",
  [ANDREANI]: "https://www.andreani.com/#!/personas",
  [RAPIBOY]: "https://rapiboy.com/",
};

export const DISABLED_RESEND_EMAIL_TIMEOUT = 20000;

//Notification's message types
export const CONTRACT_ABOUT_TO_EXPIRE = "contract_about_to_expire";
export const CONTRACT_EXPIRED = "contract_expired";
export const MERCHANT_DELETED_MARKETPLACE = "merchant_deleted_marketplace";
export const INVITATION_REJECTED = "invitation_rejected";
export const INVITATION_ACCEPTED = "invitation_accepted";
export const INVITATION_RECEIVED = "invitation_received";
export const MARKETPLACE_ACTIVATED_CARRIER = "marketplace_activated_carrier";
export const MARKETPLACE_DEACTIVATED_CARRIER =
  "marketplace_deactivated_carrier";
export const MERCHANT_ACTIVATED_CARRIER = "merchant_activated_carrier";
export const MERCHANT_DEACTIVATED_CARRIER = "merchant_deactivated_carrier";
export const MARKETPLACE_RULE_MERCHANT_EDIT_OL =
  "marketplace_rule_merchant_edit_ol";
export const MARKETPLACE_RULE_MERCHANT_NO_EDIT_OL =
  "marketplace_rule_merchant_no_edit_ol";
export const RULE_MERCHANT_REFUND = "rule_merchant_refund";
export const RULE_MARKETPLACE_MERCHANT_REFUND =
  "rule_marketplace_merchant_refund";
export const RULE_MARKETPLACE_REFUND = "rule_marketplace_refund";
export const RULE_MARKETPLACE_CANCELATION = "rule_marketplace_cancelation";
export const RULE_MARKETPLACE_MERCHANT_CANCELATION =
  "rule_marketplace_merchant_cancelation";
export const RULE_MARKETPLACE_MERCHANT_STATUS_CHANGE =
  "rule_marketplace_merchant_status_change";
export const RULE_MERCHANT_STATUS_CHANGE = "rule_merchant_status_change";
export const RULE_MARKETPLACE_STATUS_CHANGE = "rule_marketplace_status_change";
export const RULE_MERCHANT_CANCELATION = "rule_merchant_cancelation";
export const MARKETPLACE_ACTIVATED_MERCHANT = "marketplace_activated_merchant";
export const MARKETPLACE_PAUSED_MERCHANT = "marketplace_paused_merchant";
export const MARKETPLACE_DELETED_MERCHANT = "marketplace_deleted_merchant";
export const ORDERS_EXPORT_FINISHED = "orders_export_finished";
export const ORDERS_EXPORT_ERROR = "orders_export_error";
export const LABELS_DOWNLOAD_FINISHED = "labels_download_finished";
export const LABELS_DOWNLOAD_ERROR = "labels_download_error";
export const ORDER_CANCELED = "order_canceled";
export const CARRIER_ADDED = "carrier_added";

export const messageTypeIconEquivalencies: any = {
  [CONTRACT_ABOUT_TO_EXPIRE]: "contract-about-to-expiration",
  [CONTRACT_EXPIRED]: "entity-unlinks-from-carrier",
  [MERCHANT_DELETED_MARKETPLACE]: "entitity-unlinks-coati",
  [INVITATION_REJECTED]: "merchant-declines-invitation",
  [INVITATION_ACCEPTED]: "merchant-accepts-invitation",
  [INVITATION_RECEIVED]: "pending-invitations",
  [MARKETPLACE_ACTIVATED_CARRIER]: "carrier-added",
  [MARKETPLACE_DEACTIVATED_CARRIER]: "entity-unlinks-from-carrier",
  [MERCHANT_ACTIVATED_CARRIER]: "carrier-added",
  [MERCHANT_DEACTIVATED_CARRIER]: "entity-unlinks-from-carrier",
  [MARKETPLACE_RULE_MERCHANT_EDIT_OL]: "setting-added",
  [MARKETPLACE_RULE_MERCHANT_NO_EDIT_OL]: "setting-deleted",
  [RULE_MERCHANT_REFUND]: "refunds",
  [RULE_MARKETPLACE_MERCHANT_REFUND]: "refunds",
  [RULE_MARKETPLACE_REFUND]: "refunds",
  [RULE_MERCHANT_CANCELATION]: "order-cancellation",
  [RULE_MARKETPLACE_MERCHANT_CANCELATION]: "order-cancellation",
  [RULE_MARKETPLACE_CANCELATION]: "order-cancellation",
  [MARKETPLACE_ACTIVATED_MERCHANT]: "marketplace-has-activated-you",
  [MARKETPLACE_PAUSED_MERCHANT]: "marketplace-pause-merchant",
  [MARKETPLACE_DELETED_MERCHANT]: "entitity-unlinks-coati",
  [ORDERS_EXPORT_FINISHED]: "export-and-download-of-orders",
  [ORDERS_EXPORT_ERROR]: "error-in-export-or-download-process",
  [LABELS_DOWNLOAD_FINISHED]: "export-and-download-of-orders",
  [LABELS_DOWNLOAD_ERROR]: "error-in-export-or-download-process",
  [ORDER_CANCELED]: "order-cancellation",
  [CARRIER_ADDED]: "carrier-added",
  [RULE_MARKETPLACE_MERCHANT_STATUS_CHANGE]:
    "rule-marketplace-merchant-status-change",
  [RULE_MARKETPLACE_STATUS_CHANGE]: "rule-marketplace-merchant-status-change",
  [RULE_MERCHANT_STATUS_CHANGE]: "rule-marketplace-merchant-status-change",
};

export const ABLE_TO_REFUND_OR_CANCEL: { [key: string]: string[] } = {
  [MARKETPLACE]: [MARKETPLACE, MARKETPLACE_MERCHANT],
  [MERCHANT]: [MERCHANT, MARKETPLACE_MERCHANT],
  [INTERNAL]: [],
};

export const DEVELOP = "develop";
export const STAGING = "staging";
// Man
export const PRODUCTION = "production";

export const RED_DOT_URL = `${S3_BASE_URL}/icons/red-dot.svg`;
export const DATE_FORMAT = "dd/MM/yyyy";
export const SENTRY_PRIORITIES: IDynamicObjectReturnString = {
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
};

export const SENTRY_LEVELS: ISentryLevel = {
  FATAL: "fatal",
  ERROR: "error",
  WARNING: "warning",
  LOG: "log",
  INFO: "info",
  DEBUG: "debug",
};

// export const NO_CARRIER_AVAILABLE =
//   "Actualmente no has seleccionado ningún operador logístico. Por favor, elige un operador para continuar operando en la plataforma.";
// export const NO_MERCHANT_AVAILABLE =
//   "Actualmente no tienes merchants habilitados. Por favor, añade a un merchant o espera a que acepte tu invitación.";
// export const getNoCarrierAvailableMessage = (
//   marketplaceName: string
// ) => `${marketplaceName} ha desvinculado al operador logístico con el que trabajas.
// Por favor, elige otro Operador logístico para continuar operando.`;

export const MERCHANT_TABS_TRANSLATION: IDynamicObjectReturnString = {
  invitaciones: "invitations",
  administracion: "administration",
};

export const VALID_MERCHANT_TABS = ["invitations", "administration"];

export const SUMMARY_CARD_TITLES: IDynamicObjectReturnString = {
  total: "Total de envíos",
  delivered: "Total de envíos entregados",
  returned: "Total de envíos no entregados",
  returned_to_sender: "Total de devoluciones realizadas",
  canceled: "Total de envíos cancelados",
  marketplaces: "Total de marketplaces",
  ecommerces: "Total de ecommerces",
};

export const SUMMARY_SHIPMENTS_CARD_QUANTITY = 5;

export const SELECT_BUSINESS_DAYS = "Seleccionar días hábiles";
export const BUSINESS_DAYS_OPTIONS = [
  {
    id: 0,
    text: "De lunes a viernes",
  },
  {
    id: 1,
    text: "De lunes a sábado",
  },
];
export const ORDERS_SUMMARY = "ordersSummary";
export const TOTAL_COUNT = "totalCount";
export const STATUS_AND_SLA = "statusAndSLA";
export const STATUS_ORDERS_COUNT = "statusOrdersCount";
export const MARKETPLACE_ORDERS = "marketplaceOrders";
export const CLIENTS_COUNT = "clientsCount";

export const LOADING = "loading";
export const MERCHANT_SELECTED = "merchantSelected";
export const MERCHANTS_INTEGRATIONS = "merchantIntegrations";
export const ALERT = "alert";

export const INTERNAL_METRICS_URL: IDynamicObjectReturnString = {
  [CLIENTS_COUNT]: "/metrics/clientsCount",
  [ORDERS_SUMMARY]: "/internal/countByCarrier",
};

export const METRICS_URL: IDynamicObjectReturnString = {
  [ORDERS_SUMMARY]: "/metrics/countByCarrier",
  [TOTAL_COUNT]: "/users/totalCount",
  [STATUS_AND_SLA]: "/metrics/statusAndSLA",
  [STATUS_ORDERS_COUNT]: "/metrics/countByStatus",
  [MARKETPLACE_ORDERS]: "/metrics/marketplaceOrders",
  [CLIENTS_COUNT]: "/metrics/clientsCount",
  //[ORDER_SUMMARY_BY_CLIENT] //TODO: agregar cuando el back lo tenga
};

const ALL_ENTITY_METRICS = [
  ORDERS_SUMMARY,
  TOTAL_COUNT,
  STATUS_AND_SLA,
  STATUS_ORDERS_COUNT,
];

export const METRICS_ENTITY_CONTROL = {
  [MARKETPLACE]: [...ALL_ENTITY_METRICS, MARKETPLACE_ORDERS],
  [MERCHANT]: ALL_ENTITY_METRICS,
  [ECOMMERCE]: ALL_ENTITY_METRICS,
  //TODO order_summary_by_client cuando el back lo tenga
  [INTERNAL]: [CLIENTS_COUNT, ORDERS_SUMMARY],
};

export const INITIAL_CONFIG_STEPS = [
  "Contratos",
  "Merchants",
  "Notificación de estados",
  "Permisos",
  "Operadores logísticos",
];

export const CONTRACT_OWNER_OPTIONS = [
  { id: MARKETPLACE, label: "Marketplace" },
  { id: MERCHANT, label: "Merchant" },
];
export const ECOMMERCE_STEPS = ["Devolución de stock", "Operadores logísticos"];

export const INITIAL_CONFIG_SUCCESS_MESSAGES: IDynamicObjectReturnString = {
  [MARKETPLACE]:
    "Recuerda que para comenzar a operar, es necesario esperar la confirmación del Merchant.",
  [MERCHANT]:
    "Recuerda que el merchant debe confirmar la invitación y cargar las credenciales para empezar a operar.",
};

export const CONTRACT_OWNER = "contractOwner";

export const INITIAL_CONFIGURATION_PARTIAL_PATH = "configuracion-inicial";

export const SUCCESS_ALERT_DURATION = 5000;

// TODO para definir nombres correctos de los estados del usuario
export const USER_STATUS_TITLE: IDynamicObjectReturnString = {
  invited: "Invitación enviada",
  disabled: "Usuario deshabilitado",
  enabled: "Usuario habilitado",
};

export const USER_EDIT_MODAL_TYPE = "edit";

export const INCOMPLETE_CONFIG = "incomplete";

// Delete / disable user modal types
export const DELETE_USER_MODAL_TYPE = "elimination";
export const DISABLE_USER_MODAL_TYPE = "disabling";

// Roles
export const OWNER_ROLE_NAME = "Owner";
export const ADMIN_ROLE_NAME = "Administrador";
